import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'input' ]

  connect(){
    this.searchHeader = document.querySelector('#searchanchor')
  }

  showHeader() {
    if (this.search) {
      this.search.classList.add('show')
    }
  }

  hide(){
    this.searchHeader.classList.remove('invisible')
  }

  show(){
    this.searchHeader.classList.add('invisible')
  }
}