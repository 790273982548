import Rails from '@rails/ujs';

const asyncLoad = (el) => {
  Rails.ajax({
    url: el.dataset.url,
    type: "get",
    dataType: 'json',
    success: (data)=> {
      const resp = el.dataset.responseField
      const content =  el.dataset.contentTarget
      const responseField = (resp != '' && resp != undefined) ? resp : 'html'
      const contentTarget = (content != '' && content != undefined) ? content : '.dropdown-menu'
      el.querySelector(contentTarget).innerHTML = data[responseField]
    },
    error: function(data) {}
  })
}

export default asyncLoad;
