import { Controller } from "stimulus"
import  Here  from './../packs/autosuggest';

export default class extends Controller {
  static targets = ['input', 'results', 'map', 'submit', 'interphone', 'building', 'lat', 'lng', 'jsondata']

  connect() {
    this.results = []
    this.button = document.querySelector('#add-button')
    this.popOver = this.initPopoOver()
  }

  initializeMap() {
    this.map()
  }

  keyup() {
    const q =  this.inputTarget.value;
    if (q.length >  3)
    { clearTimeout(this.timeout);
      this.timeout = setTimeout( ()=> this.search(q), 500);
    }
  }

  click = (e)=> {
    this.resultsTarget.innerHTML=''
    const index = parseInt(e.target.dataset.index)
    this.item = this.results[index]
    this.inputTarget.value = this.item.title
    this.validate()
    this._map.setCenter(this.item.position)
  }

  search(q) {
    this.hideError()
    Here.geocode({
      q: q,
    }, (result) => {
      this.results = result.items
      this.resultsTarget.innerHTML = result.items.map((item, index) => {
        return `<li class="list-group-item cursor-pointer" 
        role="option" data-autocomplete-value="2"
        data-action = "click->autocomplete#click"
        data-title"${item.title}"
        data-index=${index}
        >
        ${item.title}
        </li>`
      }).join('');
    })
  }

  reverse(location){
    Here.reverseGeocode({
      at: `${location.lat},${location.lng}`
    }, (result) => {
      this.results = result.items
      this.item = this.results[0]
      this.inputTarget.value = this.results[0].title
      this.validate()
      const event = document.createEvent("Events")
      event.initEvent("focus", true, true)
      this.inputTarget.dispatchEvent(event)
    }, ()=> {
    });
  }

  map() {
    const center = new google.maps.LatLng( parseFloat(
      this.mapTarget.dataset.lat),
      parseFloat(this.mapTarget.dataset.lon)
    );
    if(this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: center,
        zoom: 14,
        disableDefaultUI: true
      })
    }
    this._map.setCenter(center)
    this._map.addListener('dragend', () => {
      this.reverse({lat: this._map.getCenter().lat(), lng: this._map.getCenter().lng()});
    });
    return this._map
  }

  marker() {
    if (this._marker == undefined) {
      this._marker = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0,0)
      })
      let mapLocation = {
        lat: parseFloat(this.latitudeTarget.value),
        lng: parseFloat(this.longitudeTarget.value)
      }
      this._marker.setPosition(mapLocation)
      this._marker.setVisible(true)
    }
    return this._marker
  } 

  validate = () => {
    if(this.hasNumber()){
      this.hideError()
      this.button.disabled=false
      this.buildingTarget.classList.remove('invisible')
      this.interphoneTarget.classList.remove('invisible')
      this.latTarget.value = this.item.position.lat
      this.lngTarget.value = this.item.position.lng
      this.jsondataTarget.value = JSON.stringify(this.item)
    } else {
       this.button.disabled=true
       this.openError()
    }
  }

  hasNumber(){
    try{
      const number = this.item.address.houseNumber
      return number.match(/\d+/gi)
    }
    catch{
      return false
    }
  }

  hideError = ()=> {
    if(this.popOver.tip && this.popOver.tip.classList.contains('show'))
      this.popOver.hide()
  }

  openError(){
     this.popOver.show()
  }

  initPopoOver(){
    const pop = new bootstrap.Popover(this.inputTarget,{ 
          placement: 'bottom',
          trigger: 'manual',
          title: 'Errore', 
          content: 'Controlla indirizzo',
          template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-header"></h3><div class="popover-body alert-danger"></div></div>'
        })
    return pop;
    }
    
  }