const service = new H.service.Platform({
  appId: 'rVpxShZCFvh744DVrSCH',
  apiKey: 'tUavXGto2OxVy2X5wjd5-7u2tQkMjgAjrKNrGFEIEMo'
});
const Here = service.getSearchService();
// Get an instance of the search service:
export default Here;

// Call the "autosuggest" method with the search parameters,
// the callback and an error callback function (called if a
// communication error occurs):
