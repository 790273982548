import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['restaurant_header', 
    'restaurants_filter',
    'restaurantHeader_card',
    'address',
    'breadcrumb',
    'category',
    'name'
];
  connect(){
    this.sticked = 0
    this.restaurantHeaderImage = document.querySelector('#restaurant-header-image')
    this.cart =  document.querySelector('#cart')
    this.isScolling = 0
    this.timer = undefined
  }

  scrolling(e) {
  this.adJustHeader()
  }
  
  adJustHeader(){
    if (this.restaurant_headerTarget.offsetTop > 5){
      this.restaurantHeaderImage.classList.add('invisible')
      this.addressTarget.classList.add('d-none')
      this.breadcrumbTarget.classList.add('d-none')
      this.restaurantHeader_cardTarget.classList.add('shadow-none', 'border-0','pb-0')
      this.restaurants_filterTarget.classList.add('one-edge-shadow')
      this.nameTarget.classList.add('restaurant-header-name__move')
      this.cart.classList.add('border-top-0')
      this.sticked = 1
    }
    else {
      this.restaurantHeaderImage.classList.remove('invisible')
      this.addressTarget.classList.remove('d-none')
      this.breadcrumbTarget.classList.remove('d-none')
      this.restaurants_filterTarget.classList.remove('one-edge-shadow')
      this.restaurantHeader_cardTarget.classList.remove('shadow-none', 'border-0','pb-0') 
      this.nameTarget.classList.remove('restaurant-header-name__move')
      this.sticked = 0
    } 
  }
  scrollTo(e){
   e.preventDefault()
   const element = document.querySelector(e.target.dataset.category)
    window.scroll({
        behavior: 'smooth',
        left: 0,
        top: element.offsetTop + 220 
      });
  }
}