import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'input' ]

  connect(){
    // this.toHide= document.querySelector(this.data.get('to-hide'))
   //  this.button = document.querySelector(this.data.get('button'))
   //  this.button.addEventListener('click', ()=>{
   //    this.show()
   //  })
  }

  hide(){
    // this.element.classList.add('d-none')
   //  this.toHide.classList.remove('d-none')
   //  this.toHide.classList.add('d-flex')
    
  }

  show(){
    // this.element.classList.remove('d-none')
   //  this.toHide.classList.add('d-none')
   //  this.toHide.classList.remove('d-flex')
   //
  }
}