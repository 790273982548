import { Controller } from "stimulus"
export default class extends Controller {
//static targets = ['restaurants', 'placeholder']

  connect() {
    this.parentFilters  = document.getElementById("multi_filters")
    this.restaurantsTag = document.getElementById("restaurants")
    this.filters = new Array()
    window.multiple_filters_id = new Array()
  }

  checkFilter(e){
    let nameFilter = e.target.dataset.name
    let idFilter   = e.target.dataset.id
    //Controllo se posso creare la div per mostrare il filtro
    if (this.filters.length == 0 || !window.multiple_filters_id.includes(idFilter)){ 
      //blocco la paginazione
      if (this.restaurantsTag){
        this.restaurantsTag.dataset.stoploading = 'true'
      }
      var selector   = document.querySelector(".checked_" + nameFilter.split(" ").join("_"))
      if (selector) { selector.classList.add("checked-filter-true") }
      //Creo la div per far visualizzare il filtro da poter rimuovere
      this.parentFilters.appendChild(this.createContainerFilter(nameFilter, idFilter))
      this.filters.push(nameFilter)//aggiungo il nome del filtro alla mia lista
      window.multiple_filters_id.push(idFilter)//Aggiungo il mio filtro alla lista
      $.getScript(window.myLocation+ "?" + this.searchParams(window.multiple_filters_id))
    }else{
      if (this.restaurantsTag){
        this.restaurantsTag.dataset.stoploading = 'false'
        this.restaurantsTag.dataset.page = 2
      }
      this.clearFilter(e)
    }
  }

  createContainerFilter(filterName, id){
    var node = document.createElement("div");
    node.classList.add(`filter-searched`)
    node.classList.add(`multi_filter_${filterName.split(" ").join("_")}`) 
    node.dataset.name   = filterName
    node.dataset.id     = id
    node.dataset.action = "click->multifilters#clearFilter"
    node.innerHTML      = `${filterName} <span class='span-multifilter' data-name=${filterName} data-id=${id}>&times;</span>`
    return node
  }
  searchParams(filters){
    if (filters.length == 0){
      var restaurantContainer = document.querySelector("#restaurants")
      restaurantContainer.dataset.stoploading = 'false'
      restaurantContainer.dataset.loading = 'false'
    }
    return filters.map(key => "filter[]=" + encodeURIComponent(key)).join("&")
  }
  clearFilter(e){
    let nameFilter = e.target.dataset.name
    let idFilter   = e.target.dataset.id
    var index      = window.multiple_filters_id.indexOf(idFilter);
    var selector   = document.querySelector(".checked_" + nameFilter.split(" ").join("_"))
    if (index > -1) {
      window.multiple_filters_id.splice(index, 1);
      let element = document.querySelector(`.multi_filter_${nameFilter.split(" ").join("_")}`)
      if (element){ element.remove() }
      if (selector) { selector.classList.remove("checked-filter-true") }
      $.getScript(window.myLocation+ "?" + this.searchParams(window.multiple_filters_id))
    }    
  }
}
