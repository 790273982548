import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['restaurants']

  connect() {
    alert('hello')
    this.page = this.data.ger('page')
  }

  scroll() {
    console.log('ciao')
    // let restaurant = tbis.targetRestaurants
  //   if (restaurant.dataset.stoploading == 'true'){
  //     return window.onscroll = undefined
  //   }
  //   let page = parseInt(restaurant.dataset.page) || 2
  //   let url = restaurant.dataset.url
  //   var lastDiv = undefined
  //   var offset = 0
  //   var h = 0
  //   if (restaurant.dataset.mobile == "true")
  //     lastDiv = document.querySelector(".restaurant_list:last-child")
  //   else
  //     lastDiv = document.querySelector("#restaurants a:last-child div")
  //   if (lastDiv) {
  //     offset = lastDiv.offsetTop
  //     h = lastDiv.clientHeight
  //   }
  //   let lastDivOffset = offset + h;
  //   let pageOffset = window.pageYOffset + window.innerHeight;
  //   let bottom = 50
  //   let loader = document.getElementById("loading_restaurants");
  //   if (pageOffset > lastDivOffset - bottom && restaurant.dataset.loading == 'false' ) {
  //     if (restaurant) restaurant.dataset.loading = 'true'
  //     if (loader) loader.style.display = "block"
  //     restaurant.dataset.page = page + 1
  //     $.getScript(`${url}?page=${page}'`)
  //   }
  } 
}