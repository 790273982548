import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.loated = false
    this.load()
  }

  load() {
  if (this.element.innerHTML.replace(/\s+/g, '') === ''){
      fetch(this.data.get("url"))
      .then(response => { return response.json() })
        .then(html => {
          this.loated = true
          this.element.innerHTML = html
          //this.element.classList.add("restaurant-fade-in");
        })
   }
  }
}