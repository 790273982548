import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['address']

  select = (e) => {
    const ancestor = ( e.target.closest("LI") != null &&  e.target.closest("LI") != undefined) 
    document.querySelector('.border-warning').classList.remove('animate__animated',  'animate__pulse', 'border-warning')
    const el = (ancestor) ?  e.target.closest("LI") : e.target
    el.classList.add('border-warning', 'animate__animated', 'animate__pulse')
  }

  add(){
    window.dispatchMapsEvent()
    document.querySelector('#addAddress').classList.remove('d-none')
    document.querySelector('#addresses').classList.add('d-none')
  }
}