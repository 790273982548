import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['input']

  connect(){
    this.loading = false 
    this.url = window.location.href
    this.resultSelector = document.querySelector(this.data.get('resultSelector'))
  }

  search(){
    window.clearTimeout(this.timer)
    this.timer = setTimeout(this.remoteSearch, 500)
  }

  remoteSearch = () => {
    const value = this.inputTarget.value
    this.resultSelector.dataset.q=value
    Rails.ajax({
      url: this.url,
      type: "get",
      data: `q=${value}&format=json`,
      success: (data)=> {
        if (data.html == ''){ 
          this.loading = false 
          this.resultSelector.innerHTML = `<div class="col-12">
          <div class="d-flex justify-content-center">
             NESSUN Risultato TROVATO :(
           </div>
          </div>`
          return
        }
        this.resultSelector.innerHTML = data.html
        this.loading = false
      },
      error: function(data) {}
    })
  } 
}