import { Controller } from "stimulus"
export default class extends Controller {
 static targets = ['image', 'cardbody']
 static timer = 300

  connect() {
   
    if( window.imagesStack == undefined)
      { window.imagesStack = [] 
      }
    
    
    if(this.imageTarget.classList.contains('to-load')){
      this.imageTarget.classList.add('d-none')
      window.imagesStack.push(this.imageTarget) 
    }
     
    
    this.imageTarget.addEventListener('animationend', () => {
      if(this.imageTarget && this.imageTarget.previousElementSibling)
       this.imageTarget.previousElementSibling.classList.remove('d-none')
    });
    this.onLoad()
  }

  
  onLoad(){
    clearTimeout(window.timer)
    window.timer = setTimeout(() =>{
      let el;
      while( el = window.imagesStack.pop() ){
              el.classList.add('animate__animated', 'animate__fadeIn', 'animate__faster')
              el.classList.remove('d-none')
              el.classList.remove('to-load')
    }
   }, this.timer )
  

     
   }
 }


