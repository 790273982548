import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {
  
  connect() {
    new Splide( '.splide',{
       type:"loop",
       perPage:4,
       perMove: 1,
       drag   : 'free',
    }
 ).mount();
 }
}


