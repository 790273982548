/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Turbolinks from 'turbolinks'
require("@rails/ujs").start()
Turbolinks.start()
// const application = Application.start()
// const context = require.context("./controllers", true, /\.js$/)
// application.load(definitionsFromContext(context))

import 'popper.js/dist/popper.js';

import Modal from 'bootstrap/js/dist/modal';
import Tooltip from  'bootstrap/js/dist/tooltip';
import Popover from 'bootstrap/js/dist/popover';
import Dropdown from 'bootstrap/js/dist/dropdown';
import ScrollSpy from 'bootstrap/js/dist/scrollspy';

import Rails from '@rails/ujs';
import "controllers"
import 'bootstrap/js/dist/collapse';
import './mobileCheck' 
import attachListener from './options'
import asyncLoad from './asyncLoad'

window.bootstrap = {}
window.bootstrap.Tooltip = Tooltip
window.bootstrap.Popover = Popover
window.bootstrap.Modal = Modal
window.Turbolinks = Turbolinks


document.addEventListener('turbolinks:load', () =>{
  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-toggle="popover"]'))
  const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
     new bootstrap.Popover(popoverTriggerEl)
  })

  // remove animation class for 1 anumation after cache
  const elements = document.querySelectorAll('.animate__animated');
  elements.forEach( (elem)=>{
    elem.addEventListener('animationend', (e) => {
       e.target.classList.remove('animate__animated')
    });
  })

  //load remote contet dropdown
  const myDropdowns = document.querySelectorAll('.dropdown.remote')
  myDropdowns.forEach( (elem)=>{
    elem.addEventListener('show.bs.dropdown', function () {
      asyncLoad(this)
    })
  })

  const modals = document.querySelectorAll('.modal.remote')
  modals.forEach( (elem)=>{
    elem.addEventListener('show.bs.modal', function () {
      asyncLoad(this)
    })
  })
  
 // disable complete order while update the page on
 // checkout Pagamento
  //send the value change
  // const changToSend = document.querySelectorAll('.input-on-change-send')
//   changToSend.forEach( (elem)=>{
//     elem.addEventListener('change', function () {
//        alert('hello')
//     })
//   })

  // let items = document.querySelectorAll('.carousel .carousel-item')
  // items.forEach((el) => {
  //   console.log('----------')
  //     const minPerSlide = 4
  //     let next = el.nextElementSibling
  //     for (var i=1; i < minPerSlide; i++) {
  //         if (!next) {
  //           next = items[0]
  //         }
  //         let cloneChild = next.cloneNode(true)
  //         el.appendChild(cloneChild.children[0])
  //         next = next.nextElementSibling
  //     }
  // })
 
  //COOKIES
  const accetpCookies =  window.getCookie('acceptedCookie')
  if(!accetpCookies){
    var myDiv = document.createElement("div");
    myDiv.innerHTML = `<div data-turbolinks = false  
      class="card text-center text-white fixed-bottom bg-dark" style="opacity: 0.9">
     <div class="card-body">
       <h5 class="card-title">Questo sito utilizza cookies tecnici, analytics e di terze parti.</h5>
       <p class="card-text">Proseguendo nella navigazione accetti l’utilizzo dei cookie.</p>
       <a href="#" class="text-decoration-none btn-lg btn-green" onclick="acceptCookie();return this.parentElement.parentElement.remove()">Si Accetto! </a>
     </div>
   </div>`;
 
    //Finally, append the element to the HTML body
    document.body.appendChild(myDiv);
   }

})
window.onChangesubmit = (e) =>{
  const form = e.parentElement;
  Rails.fire(form, 'submit')
}
export default onChangesubmit;
// send event to load map
window.dispatchMapsEvent =  (...args) => {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}


// close modal before cache
document.addEventListener("turbolinks:before-cache", (e)=> {
    let modalEls = document.querySelectorAll('.modal.show')
    modalEls.forEach( (modalEl) => {
      let mod = bootstrap.Modal.getInstance(modalEl)
      if(mod != null && mod != undefined){
        console.log('close', modalEl)
         mod.hide()
      }
    })
})

window.acceptCookie = () =>{
  createCookie('acceptedCookie', true, 1000)
}

window.createCookie = (name, value, days) => {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

window.getCookie = (c_name) => {
    if (document.cookie.length > 0) {
      var c_end;
       var c_start = document.cookie.indexOf(c_name + "=");
        if (c_start != -1) {
            c_start = c_start + c_name.length + 1;
            c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return "";
}
