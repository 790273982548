import { Controller } from "stimulus"

export default class extends Controller {
   static targets = ['restaurants']
  connect() {
    this.loated = false
    this.load()
  }

  load() {
    if (this.element.innerHTML.replace(/\s+/g, '') === ''){
      fetch(this.data.get("url"))
        .then(response => { return response.json() })
        .then(data => {
          var repart = document.getElementById('repart_header')
          var delivery_hours = document.getElementById("restaurant_delivery_hours")
          var placeholder = document.getElementById("placeholder_menus")
          this.loated = true
          this.element.innerHTML = data.reparts
          if (repart){ 
            repart.innerHTML = data.reparts_header
            scrollRepart(window.document, window.history, window.location)
          }
          if (delivery_hours) {
            delivery_hours.innerHTML = data.delivery_hours
            document.querySelectorAll('.restaurant_list').forEach(function(restaurant){
              calculateTimeBannerCardRestaurant(restaurant)
            })
          }
          if (placeholder){
            $(placeholder).fadeOut()
          }
          this.element.classList.add("restaurant-fade-in");
      })
   }
  }
}