import { Controller } from "stimulus"
import attachListener from "../packs/options"

export default class extends Controller {
  static targets = ['submit', 'itemQuantity', 'modal', 'itemQuantityLabel', 'supplement'];

  connect() {
   this.initialize()
  }

  initialize(){
    attachListener()
    this.options_price = new Array()
    this.required_option = new Array()
    this.unrequired_option = new Array()
    this.checkbox_required_option = new Array()
    this.quantity = 1
    this.total_price = this.price * this.quantity
    this.container_quantity = document.getElementById('current_quantity-item')
    this.checkbox_checked = 0
    this.radio_checked = 0
    this.checkbox_required = 0
    this.error_form = false
    this.edit_option = false
    window.init_from_edit = false
    window.parent_id_checkbox = undefined
  }
  select_checkbox(){
    this.option_hash = {id: event.target.dataset.id, price: event.target.dataset.price}
    this.max_items = event.target.getAttribute('data-max-items')
    this.option_hash_req = `${event.target.getAttribute('data-option-parent-id')}`
    this.required = event.target.getAttribute('data-option-required')
    if (event.target.checked){
      this.updateCheckboxes(event)
      if (this.unrequired_option[this.parent_option].count_check <= this.max_items){
        this.add_price(this.option_hash)
        if (this.checkbox_required_option[this.option_hash_req] == undefined && this.required == "true"){
            this.checkbox_required_option[this.option_hash_req] = {id: event.target.getAttribute('data-option-item'), price: event.target.dataset.price}
            this.checkbox_required += 1
        }
      }
    }else{
      this.decreaseCheckbox(event)
      this.remove_item(this.option_hash.id)
      if (this.checkbox_required_option[this.option_hash_req] != undefined && this.required == "true") {
        if (this.checkbox_required_option[this.option_hash_req].id == event.target.getAttribute('data-option-item')){
          delete this.checkbox_required_option[this.option_hash_req]
          if (this.checkbox_required > 0) {
            this.checkbox_required -= 1
          }
        }
      }
    }
    this.enable_disable_checkbox(event)
    this.enable_form()
  }

  select_radio(){
    this.option_hash = {id: event.target.dataset.id, price: event.target.dataset.price}
    this.parent_option = `${event.target.getAttribute('data-option-id')}`
    if (this.required_option[this.parent_option] != undefined) {
       this.remove_item(this.required_option[this.parent_option].id);
       this.radio_checked -= 1
    }
    this.radio_checked += 1
    this.required_option[this.parent_option] = this.option_hash
    this.add_price(this.option_hash)
    this.enable_form()
  }

  add_price(option){
    this.options_price.push(option)
    this.calc_price()
    this.show_total_price()
  }

  remove_item(option_id){
    this.remove_option("id", option_id)
    this.calc_price()
    this.show_total_price()
  }

  updateCheckboxes(event){
    this.selector = `.label-checkbox-options.checkbox-lbl-${event.target.getAttribute('data-option-id')}.checked`
    this.checkbox_checked = document.querySelectorAll(this.selector).length
    if (this.checkbox_checked == 0){
      this.checkbox_checked = 1;
    }else{
      this.checkbox_checked + 1;
    }
    this.initHash = {count_check: this.checkbox_checked}
    this.parent_option = `${event.target.getAttribute('data-option-id')}`
    if (this.unrequired_option[this.parent_option] != undefined){
      this.unrequired_option[this.parent_option].count_check += 1
    }else{
      this.unrequired_option[this.parent_option] = this.initHash
    }
  }

  decreaseCheckbox(event){
    this.parent_option = `${event.target.getAttribute('data-option-id')}`
    this.selector = `.label-checkbox-options.checkbox-lbl-${event.target.getAttribute('data-option-id')}.checked`
    this.initHash = {count_check: document.querySelectorAll(this.selector).length}
    this.parent_option = `${event.target.getAttribute('data-option-id')}`
    this.edit = event.target.getAttribute('data-edit')
    this.unrequired_option[this.parent_option] = this.initHash
    if (this.unrequired_option[this.parent_option].count_check > 0 && this.edit == "false"){
      this.unrequired_option[this.parent_option].count_check -= 1
    }else{
      this.unrequired_option[this.parent_option].count_check -= 1
    }
    if (this.unrequired_option[this.parent_option].count_check == 0) window.init_from_edit = true
  }

  remove_option(key, value) {
      if (value == undefined) return;
      for (var i in this.options_price) {
          if (this.options_price[i][key] == value) {
              this.options_price.splice(i, 1);
          }
      }
  }

  calc_price(){
   return this.total = this.options_price.reduce(function(sum, option){
        sum += parseFloat(option.price);
        return parseFloat(sum);
        }, 0);
  }

  decrease_quantity(){
    if (this.quantity > 1){
       this.quantity -= 1
    }
    this.set_quantity()
  }

  increase_quantity(){
    this.quantity += 1
    this.set_quantity()
  }

  show_total_price(){
    this.add_option = document.querySelector('#add_options')
    this.price = parseFloat(this.add_option.dataset.startprice)
    this.total_price = (this.price + this.calc_price()) * this.quantity
    if (this.error_form){
       this.add_option.value = `* Aggiungi tutte le opzioni obbligatorie`
    }else{
      if (this.edit_option){
       this.add_option.value = `TOTALE`
      }else{
       this.add_option.value = `TOTALE ${this.total_price.toFixed(2)} €` 
      }
    }
  }

  set_quantity(){
    this.itemQuantityTarget.value =  this.quantity
    this.itemQuantityLabelTarget.innerHTML = `${this.quantity}`
    // document.getElementById("item_quantity_option").value = `${this.quantity}`
  //   document.getElementById('current_quantity-item').innerHTML = `${this.quantity}x`
    this.show_total_price()
  }

  add(){

  }

  submit_form(){
    this.submit_btn = document.getElementById('add_options')
    this.required_num = parseInt(this.submit_btn.dataset.requiredmax)
    if ((this.radio_checked + this.checkbox_required)  < this.required_num){
      event.preventDefault();
      this.handleValidationErrorForm();
    }else{
       bootstrap.Modal.getInstance(this.modalTarget).hide()
       this.initialize()
       if(document.querySelector('open_options_small'))
         bootstrap.Modal.getInstance(document.querySelector('open_options_small')).hide()
  //    $("#open_options").modal('hide')
  //    $("#open_options_small").modal('hide')
    }
  }

  submit_form_edit(){
    bootstrap.Modal.getInstance(this.modalTarget).hide()
    if(document.querySelector('open_options_small'))
      bootstrap.Modal.getInstance(document.querySelector('open_options_small')).hide()
  }

  enable_disable_checkbox(event){
    this.max_items = event.target.getAttribute('data-max-items')
    this.edit = event.target.getAttribute('data-edit')
    if (this.edit == "true") {this.edit_option = true;}
    window.parent_id_checkbox = `${event.target.getAttribute('data-option-id')}`
    if (this.unrequired_option[this.parent_option].count_check >= this.max_items){ //DISABLE CHECKBOX
      if (this.edit == "true" && window.init_from_edit != true){
        this.validateCheckboxes()
      }
      this.disable_all_checkbox()
    }else{
      this.enable_all_checkbox()
    }
  }

  disable_all_checkbox(){
    setTimeout(function(){
      document.querySelectorAll(`.label-checkbox-options.checkbox-lbl-${window.parent_id_checkbox}`).forEach(function(label, index){
        if (label.classList.length == 2){
         label.querySelector('input').disabled = true
         label.classList.add("disabled")
        }
      })
      window.init_from_edit = false
    }, 150)
  }

  enable_all_checkbox(){
    document.querySelectorAll(`.label-checkbox-options.checkbox-lbl-${window.parent_id_checkbox}`).forEach(function(label, index){
       label.querySelector('input').disabled = false
       label.classList.remove("disabled")
    })
  }

  handleValidationErrorForm(){
    this.error_form = true
    setTimeout(()=>{
      this.submitTarget.setAttribute("disabled", "true")
      this.submitTarget.value = "* Aggiungi tutte le opzioni obbligatorie"
      document.querySelector(".body-smooth-scroll").scroll({
          behavior: 'smooth',
          left: 0,
          top:0
        });
      document.querySelector('#errors').style.display="block"
    }, 200)
  }

  handleValidationSuccessForm(){
    setTimeout(()=>{
      this.submitTarget.removeAttribute("disabled");
      document.querySelector('#errors').style.display="none"
    }, 100)
  }

  enable_form(){
    this.required_num = parseInt(this.submitTarget.dataset.requiredmax)
    if ((this.radio_checked + this.checkbox_required) == this.required_num){
      this.error_form = false
      this.handleValidationSuccessForm();
      this.set_quantity()
    }
  }

  validateCheckboxes(event){
    this.checkboxes = document.querySelectorAll(`.label-checkbox-options.checkbox-lbl-${window.parent_id_checkbox}.checked`)
    this.last = this.checkboxes[this.checkboxes.length - 1]
    this.last.classList.add("disabled")
    this.last.querySelector('input').removeAttribute("checked");
    this.last.querySelector('span').classList.remove("checked", "reset-transform-radio")
    this.last.classList.remove("checked")
  }

  addSupplement(e) {
    const target = e.target
    console.log(target.nextElementSibling)
    if(parseInt(target.dataset.checked) == 0){
      target.dataset.checked = 1
      target.classList.remove('btn-pil-green')
      target.classList.add('bg-danger')
      target.querySelector('.removed').classList.remove('d-none')
      target.nextElementSibling.checked = 1
    }else{
      target.dataset.checked = 0
      target.classList.toggle('btn-pil-green')
      target.classList.toggle('bg-danger')
      target.querySelector('.removed').classList.toggle('d-none')
      target.nextElementSibling.checked = 0
    }
  }
}