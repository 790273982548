import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.load()
  }

  load() {
    if (window.currentBasketTimestamp && window.currentBasketTimestamp != parseInt(this.data.get("timestamp"))){
    this.element.style.opacity = "0.5"
    fetch(`${this.data.get("url")}?items=true` )
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html
        this.element.style.opacity = "1.0"
        window.currentBasketTimestamp = undefined
    })
   }
  }
}