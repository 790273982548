import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['search' , 'formGroup', 'label']
  
  // focus(){
 //    this.formGroupTarget.classList.add('focused')
 //    this.searchTarget.classList.add('filled')
 //    this.labelTarget.dataset.label_back = this.labelTarget.innerHTML
 //    this.labelTarget.innerHTML = this.labelTarget.dataset.label
 //  }
 //
 //  focusout(){
 //    if ( this.searchTarget.value.length === 0){
 //       this.formGroupTarget.classList.remove('focused')
 //       this.searchTarget.classList.remove('filled')
 //       this.labelTarget.innerHTML = this.labelTarget.dataset.label_back
 //    }
 //  }
  back(){
    document.querySelector('#addAddress').classList.add('d-none')
    document.querySelector('#addresses').classList.remove('d-none')
  }
}