import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
 static targets = ['categories','restaurants', 'placeholder']

  connect() {
   this.filters = JSON.parse(this.data.get('activeFilters')) 
  }

  add(e){
    document.querySelector('.categories__active').classList.remove('categories__active')
    e.target.closest('li').classList.add('categories__active')
    this.categoriesTarget.data.set('filters',  e.target.href)
  //   target.classList.add('categories__active')
   }

   del(e){
     // e.preventDefault();
 //     const id = e.target.dataset.id
 //     const index = this.filters.indexOf(id)
 //     if(index > -1 ){
 //      this.filters.splice(index, id)
 //      this.updateUrl()
 //     }
   }

   updateUrl(){
  //    const search = this.filters.map( (id) => `filter[]=${id}`).join('&')
//      let urlStart;
// //     urlStart = (!window.location.search.includes('?')) ? '?' : '&'
//     // window.location.search = urlStart + search;
//   //   Turbolinks.visit(window.location.href + urlStart + search)
//      this.data.set('activeFilters', JSON.stringify(this.filters))
//      Rails.ajax({
//         url: window.location.href,
//         type: "get",
//         data: search,
//     })
   }
}
