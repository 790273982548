import Modal from 'bootstrap/js/dist/modal';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const mobileCheck = isMobile

document.addEventListener('turbolinks:load', () =>{
  if(!isMobile) return 
  const myModal = document.getElementById('mobileBanner')
  if(!myModal) return
  let mod = new bootstrap.Modal(myModal)
  mod.show()
})

export default mobileCheck;
