import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['list']

  connect() {
   this.page = parseInt(this.data.get('page'))
   this.loading = false
   this.offset = 0
   this.lastSelector = this.data.get('lastSelector')
   this.initSelector()
   this.bottom = 50
   this.lastPage = false
   this.disabled = JSON.parse(this.data.get('disabled'))

  }

  initSelector(){
    this.lastDiv = this.listTarget.querySelector(`${this.lastSelector}:last-child`)
  }

  scroll(el) {
    if(this.disabled) return;
    if(this.lastPage || this.loading || !this.lastDiv) return;

    const query = this.listTarget.dataset.q
    if(query) return;

    const offset = this.lastDiv.offsetTop
    const h      = this.lastDiv.clientHeight

    const lastDivOffset = offset + h;
    const pageOffset   = window.pageYOffset + window.innerHeight;

    // added to prevend infinite page request when back from show page
    const lastPage = this.data.get('lastPage')
  
    if(pageOffset > lastDivOffset - this.bottom && !this.loading && ! lastPage){
      this.loading = true
      this.page += 1
      Rails.ajax({
        url: window.location.href,
        type: "get",
        data: `page=${this.page}&format=json`,
        success: (data)=> {
          if (data.html == '' || data.html == undefined){ 
            this.lastPage = true
            this.loading = false
            this.data.set('lastPage', true) 
            return
          }
          this.listTarget.insertAdjacentHTML('beforeend',data.html)
          this.initSelector()
          this.loading = false
        },
        error: function(data) {}
      })
    }
  } 
}

