import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
 static targets = ['stores', 'city' ,'title', 'searchCity', 'result']

  connect() {
    this.url = this.data.get('url')
    this.loading = false
    this.loadStores()
  }


  loadStores(){
    this.loading = true
    if(!this.url) return 
     fetch(`${this.url}?page=${this.page}&format=json`)
     .then(response => response.json())
     .then( (data)=> {
        this.storesTarget.innerHTML = data.html
        this.loading = false
        if (data.logged == true){
          // this.homeCityNames.forEach((el) =>{
  //           el.dataset.target = '#addressModal'
  //         })
        }
       this.loading = false
      }
    )
  }

  search(){
    const value = this.searchCityTarget.value
    if(value.lenght == 0) return
    const valueLover = value.toLowerCase()

    this.resultTargets.forEach( city => {
      const cityName     =  city.dataset.name
      const cityNameLower = cityName.toLowerCase()
      const match         = cityNameLower.search(valueLover)
      if(match == -1){
        city.classList.add('d-none')
      }
      else{
        //const reg =  RegExp(value,'ig')
        // const replace = cityName.replace(reg, `<mark>${value}</mark>` )
      //  city.innerHTML = cityName
        city.classList.remove('d-none')

      }
    }
    )
  }
}
